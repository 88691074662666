import {Badge, Button, Image, OverlayTrigger, ProgressBar, Tooltip} from 'react-bootstrap'
import {getPrimaryPercent, getPrimaryText} from '../../utils/stats'
import {commonAssets, raceAssets, minionAssets, buffAssets} from '../../utils/assets'
import CastBar from './CastBar'

const Character = ({character, isSelected, isAggressive, isInCombat, setTarget, moveAnimation, actionResults = [], isAllies}) => {
  const isEnraged = character.stats.buffs && Object.values(character.stats.buffs).find((bu) => bu.name === 'Enraged')

  return <Button
    className={'my-1 mx-2 p-0' + (moveAnimation ? ' move-' + moveAnimation : '') + (isEnraged ? ' enraged' : '')}
    variant={isAggressive ? 'danger' : 'secondary'}
    type="button"
    onClick={setTarget}
    style={{
      ...styles.character,
      width: (character.type === 'minion' || character.type === 'pet') ? 64 : 70,
      border: isSelected ? '2px solid rgb(101, 241, 255)' : '2px solid transparent',
    }}
  >
    <div className="above-block">
      {/* ========= Current combat target ========= */}
      {isInCombat && <div className="combat-target">{isInCombat}</div>}

      {/* ========= Buffs ========= */}
      <div className="buff-wrapper">
        {character.stats.buffs && Object.values(character.stats.buffs).map((buff) =>
          <OverlayTrigger
            key={buff.key}
            placement="top"
            delay={{show: 300, hide: 300}}
            overlay={
              <Tooltip className="custom-tooltip">
                <div>
                  <strong>{buff.name}</strong>
                  <span className="float-end">{buff.ticks} ticks left</span><br/>
                  {buff.description}
                </div>
              </Tooltip>
            }
          >
            <Badge bg={buff.type === 'debuff' ? 'danger' : 'success'} className="buff-badge">
              {buff.ticks}
            </Badge>
          </OverlayTrigger>
        )}
      </div>
    </div>

    {/* ========= CC buffs ========= */}
    {character.stats.buffs && Object.values(character.stats.buffs).map((bu) => <>
      {bu.name === 'Stun' && <div key={bu.key} className="front-block"><Image src={buffAssets.stun} style={{
        filter: 'invert(1)',
        width: 40,
      }}/></div>}
      {bu.name === 'Freeze' && <div key={bu.key} className="front-block"><Image src={buffAssets.freeze} style={{
        filter: 'invert(1)',
        width: 40,
      }}/></div>}
    </>)}

    {/* ========= Death overlay ========= */}
    {character.stats.primary.hp <= 0 && character.userId && <div className="death-block">
      DEAD
      <Image src={commonAssets.death}/>
    </div>}

    {/* ========= Shield ========= */}
    {character.stats.primary.shield > 0 && <ProgressBar
      now={getPrimaryPercent(character, 'shield')}
      label="shield"
      variant="warning"
      style={styles.progress}
    />}

    {/* ========= HP ========= */}
    <ProgressBar
      now={getPrimaryPercent(character, 'hp')}
      label={getPrimaryText(character, 'hp')}
      variant="success"
      className="mb-1"
      style={styles.progress}
    />

    {/* ========= Race Class Name ========= */}
    <div className="mb-1" style={styles.characterTitle}>
      {(character.type === 'minion' || character.type === 'pet') ? <>{character.master}'s minion</> : <>{character.race || ''} {character.gameClass || ''}</>}
      <br/>
      <strong style={styles.characterName}>{character.name}</strong>
    </div>

    {/* ========= Race image ========= */}
    <div style={{
      height: (character.type === 'minion' || character.type === 'pet') ? 50 : 64,
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      backgroundImage: `url(${(character.type === 'minion' || character.type === 'pet') ? minionAssets[character.name] : raceAssets[(character.race)]})`,
    }}/>

    {/* ========= Mana ========= */}
    <ProgressBar
      now={getPrimaryPercent(character, 'mana')}
      label={getPrimaryText(character, 'mana')}
      style={styles.progress}
    />

    {/* ========= Cast bar ========= */}
    {character.stats.spellCast && character.stats.spellCast.name &&
      <CastBar castTime={character.stats.spellCast.time} spellName={character.stats.spellCast.name}/>}

    {/* ========= Float up info ========= */}
    {actionResults.map((d) => <div
      key={`char-${d.key}`}
      className={`damage hit-${d.hitType}`}
      style={{
        left: (d.hitType === 'ability' || d.hitType === 'skill' || d.hitType === 'chat') ? (isAllies ? '105%' : 'unset') : d.left,
        right: ((d.hitType === 'ability' || d.hitType === 'skill' || d.hitType === 'chat') && !isAllies) ? '105%' : 'unset',
        animationDelay: d.delay,
        borderBottomRightRadius: isAllies ? 15 : 0,
        borderBottomLeftRadius: isAllies ? 0 : 15,
        textAlign: isAllies ? 'left' : (d.hitType === 'ability' || d.hitType === 'skill' || d.hitType === 'chat' ? 'right' : 'left'),
      }}>
      {d.value}
    </div>)}
  </Button>
}

const styles = {
  character: {
    fontSize: '9px',
    textAlign: 'center',
    position: 'relative',
    display: 'inline-block',
    transition: 'all 300ms linear',
  },
  characterTitle: {
    lineHeight: '10px',
    wordWrap: 'break-word',
  },
  characterName: {
    fontSize: '13px',
  },
  progress: {
    fontSize: '8px',
    height: '0.5rem',
    color: '#000',
  },
}

export default Character
