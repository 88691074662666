

const Minimap = ({minimap}) => {
  return (
    <div className="minimap">
      <div className="minimap-inner">

        <div className="room">
          <small>123</small>
          <div className="route-north"></div>
          <div className="route-south"></div>
          <div className="route-west"></div>
        </div>

      </div>
    </div>
  )
}

export default Minimap
