const Fade = ({fadeOut = null}) => {
  return <>
    <div className={fadeOut ? 'fade-out' : ''} style={styles.fadeBlock}></div>
  </>
}

const styles = {
  fadeBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#000',
    opacity: 0,
    zIndex: 0,
  },
}

export default Fade
