import Item from './Item'

const Inventory = ({inventory, isActionsBlocked, handleAction, setTarget}) => {
  return (
    <>
      {inventory ? Object.values(inventory).map((item) => (
        <Item
          key={item._id}
          item={item}
          actions={{
            ...(item.slot !== 'inventory' && item.slot !== 'immovable' && {equip: 'Equip'}),
            drop: 'Drop',
            ...(item.use && {use: 'Use'}),
            ...(item.slot !== 'immovable' && {sacrifice: 'Sacrifice'}),
          }}
          isActionsBlocked={isActionsBlocked}
          handleAction={handleAction}
          setTarget={setTarget}
        />
      )) : <small>empty</small>}
    </>
  )
}

export default Inventory
