import {useState, useEffect} from 'react'
import CreateNewCharacter from './CreateNewCharacter'
import logger from '../utils/logger'
import {Button, Col, Image, Row} from 'react-bootstrap'
import {raceAssets} from '../utils/assets'

const Lobby = ({socket, onLogout}) => {
  const [showCreate, setShowCreate] = useState(false)
  const [characters, setCharacters] = useState([])
  const [currentCharacterId, setCurrentCharacterId] = useState()

  useEffect(() => {
    logger.log('Lobby :: EMIT :: getCharacters')

    socket.emit('getCharacters', (response) => {
      setCharacters(response.characters)
      if (response.characters.length > 0) {
        setCurrentCharacterId(response.characters[0]._id)
      } else {
        setShowCreate(true)
      }
      logger.log('Lobby :: EMIT RESPONSE :: getCharacters :: ' + JSON.stringify(response.characters))
    })
  }, [])

  const handleEnterWorld = () => {
    logger.log('Lobby :: EMIT :: enterWorld')
    socket.emit('enterWorld', {currentCharacterId})
  }

  const handleSetCharacters = (c) => {
    setCharacters(c)
    setShowCreate(false)
    setCurrentCharacterId(c(characters).slice(-1)[0]._id)
  }

  return (<>
    {showCreate ? (
      <CreateNewCharacter socket={socket} setCharacters={handleSetCharacters} onGoBack={() => setShowCreate(false)}/>
    ) : (<>
        <Row className="justify-content-center mb-4">
          <Col md={4} className="mt-3 mt-lg-4">
            <Button variant="secondary" type="button" onClick={onLogout}>Logout</Button>
            <Button
              className="float-end"
              onClick={() => setShowCreate(true)}
              variant="success"
              type="button"
              disabled={characters.length >= process.env.REACT_APP_MAX_CHARACTERS}
            >
              New character
            </Button>
          </Col>
        </Row>

        <Row className="text-center mb-4">
          <Col>
            <h2>Select Character</h2>
          </Col>
        </Row>

        <Row className="mb-4">
          {characters.length > 0 && (
            <Col className="text-center">
              {characters.map(character => (
                <div
                  key={character._id}
                  className={'dw-card selectable mx-2 mb-4 p-2' + (character._id === currentCharacterId ? ' active ' : '')}
                  onClick={() => {
                    setCurrentCharacterId(character._id)
                  }}
                >
                  <Image
                    style={styles.selectable}
                    src={raceAssets[character.race]}
                    alt={character.name}
                    className="mr-3 mb-2"
                    thumbnail
                  />
                  <div><strong className="mb-1">{character.name}</strong></div>
                  {character.race || ''} {character.gameClass || ''} <br/>
                  Level {character.level || 1}
                </div>
              ))}
            </Col>
          )}
        </Row>

        <Row className="justify-content-center mb-4">
          <Col md={4}>
            <Button
              disabled={characters.length <= 0}
              className="w-100"
              variant="success"
              type="button"
              onClick={handleEnterWorld}
            >
              Enter World
            </Button>
          </Col>
        </Row>
      </>
    )}
  </>)
}

const styles = {
  selectable: {
    width: 300
  },
}

export default Lobby
