import {Badge, Button, OverlayTrigger, Popover} from 'react-bootstrap'
import {getPrefix, getUnits, statToText} from '../../utils/stats'

const Item = ({item, isSelected, actions, actionResults = [], isActionsBlocked, handleAction, setTarget, isAllies}) => {
  const popover = (
    <Popover style={{width: '18rem'}} border={qualityToBg(item.quality)}>
      <Popover.Header className="position-relative px-2 py-1">
        <sup>level {item.level}</sup> {item.slot}
      </Popover.Header>
      <Popover.Body className={'p-2' + (item.type !== 'common' ? ' pt-0' : '')}>
        {item.type !== 'common' && <span className="custom-list-item p-0"><sub>{item.type}</sub><br/></span>}

        <strong>{item.name + (item.stackable && item.quantity > 1 ? (` (${item.quantity})`) : '')}</strong>

        {qualityToBadge(item.quality)}

        {!item.stackable && item.use && item.quantity > -1 && <span className="custom-list-item p-0">
                  <br/>
                  <small>charges: {item.quantity}</small>
              </span>}

        {item.stats && Object.keys(item.stats).map((stat, i) => (
          <span key={stat} className="custom-list-item p-0">
                  <br/>
                  <small>{stat} <span className="float-end">
                    {getPrefix(stat)}{statToText(item.stats[stat])}{getUnits(stat)} {item.slot === 'main hand' && stat === 'damage' ? ` / ${Number.parseFloat((item.speed / 1000).toFixed(1))}s` : ''}
                  </span></small>
                </span>
        ))}

        {actions && <div className="mt-2">
          {Object.keys(actions).map((action) => (
            <Button
              key={action}
              variant={(action === 'drop' || action === 'sacrifice') ? 'danger' : 'secondary'}
              className="me-1 mb-1"
              size="sm"
              onClick={() => {
                handleAction(action, item)
              }}
              disabled={isActionsBlocked}
            >
              {actions[action]}
            </Button>
          ))}
        </div>}
      </Popover.Body>
    </Popover>
  );

  return <OverlayTrigger
    trigger="click"
    placement="top"
    overlay={popover}
    rootClose={true}
  >
    <Button
      key={item._id}
      variant={qualityToClass(item.quality)}
      type="button"
      size="sm"
      className="d-inline-block me-1 mb-1 px-1 py-0 position-relative"
      style={{
        border: isSelected ? '2px solid rgb(101, 241, 255)' : '2px solid transparent',
      }}
      onClick={() => {
        setTarget({type: 'item', id: item._id})
      }}
    >
      {item.name + (item.stackable && item.quantity > 1 ? (' (' + item.quantity + ')') : '')}

      {actionResults.map((d) => <div
        key={`item-${d.key}`}
        className={`damage hit-${d.hitType}`}
        style={{
          left: (d.hitType === 'ability' || d.hitType === 'skill' || d.hitType === 'chat') ? (isAllies ? '105%' : 'unset') : d.left,
          right: ((d.hitType === 'ability' || d.hitType === 'skill' || d.hitType === 'chat') && !isAllies) ? '105%' : 'unset',
          animationDelay: d.delay,
        }}>
        {d.value}
      </div>)}
    </Button>
  </OverlayTrigger>
}

const qualityToClass = (quality) => {
  if (quality === 0) {
    return 'light'
  } else if (quality === 1) {
    return 'warning'
  } else if (quality === 2) {
    return 'info'
  } else if (quality === 3) {
    return 'success'
  } else if (quality === 4) {
    return 'danger'
  }
}

const qualityToBg = (quality) => {
  if (quality === 0) {
    return 'light'
  } else if (quality === 1) {
    return 'warning'
  } else if (quality === 2) {
    return 'info'
  } else if (quality === 3) {
    return 'success'
  } else if (quality === 4) {
    return 'danger'
  }
}

const qualityToBadge = (quality) => {
  if (quality === 0) {
    return ''
  } else if (quality === 1) {
    return <Badge bg="warning" className="float-end">rare</Badge>
  } else if (quality === 2) {
    return <Badge bg="info" className="float-end">epic</Badge>
  } else if (quality === 3) {
    return <Badge bg="success" className="float-end">set</Badge>
  } else if (quality === 4) {
    return <Badge bg="danger" className="float-end">legendary</Badge>
  }
}

export default Item
