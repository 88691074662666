import {ListGroup} from 'react-bootstrap'
import Item from './Item'

const Equipment = ({equipment, isActionsBlocked, handleAction, setTarget}) => {
  return (
    <ListGroup>
      {equipment ? Object.keys(equipment)
        .sort((a, b) => sortWeights[a] > sortWeights[b])
        .map((item) => (
          <ListGroup.Item
            key={item}
            className="equipment-list-item px-0 py-1 d-flex align-items-center justify-content-between"
          >
            {item}
            <Item
              item={equipment[item]}
              actions={{
                unequip: 'Unequip',
                drop: 'Drop',
                ...(equipment[item].use && {use: 'Use'}),
                ...(item.slot !== 'immovable' && {sacrifice: 'Sacrifice'}),
              }}
              isActionsBlocked={isActionsBlocked}
              handleAction={handleAction}
              setTarget={setTarget}
            />
          </ListGroup.Item>
        )) : <small>empty</small>}
    </ListGroup>
  )
}

const sortWeights = {
  'main hand': 0,
  'off hand': 1,
  head: 2,
  neck: 3,
  shoulders: 4,
  chest: 5,
  back: 6,
  waist: 7,
  wrist: 8,
  hands: 9,
  finger: 10,
  legs: 11,
  feet: 12,

}

export default Equipment
