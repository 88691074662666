import {ListGroup} from 'react-bootstrap'
import {statToText} from '../../utils/stats'

const Skills = ({skills, skillBonus = null}) => {
  return (
    <ListGroup>
      {Object.keys(skills)
        .sort((a, b) => sortWeights[a] > sortWeights[b])
        .map((key) => (
          <ListGroup.Item key={key} className="stats-list-item p-0 d-flex align-items-center justify-content-between">
            <small>{skills[key].name}</small>
            <small>{skills[key].skill} {skillBonus && `(+${statToText(skillBonus)})`}</small>
          </ListGroup.Item>
        ))}
    </ListGroup>
  )
}

const sortWeights = {
  'attack': 0,
}

export default Skills
