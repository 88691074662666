import React, {useState, useEffect, useRef} from 'react'
import {Button, Form, InputGroup, ListGroup} from 'react-bootstrap'

const Chat = ({name, socket}) => {
  const [message, setMessage] = useState('')
  const [chatHistory, setChatHistory] = useState([])
  const chatEndRef = useRef(null);

  useEffect(() => {
    socket.on('chatMessage', (newMessage) => {
      setChatHistory((prevHistory) => {
        const updatedHistory = [...prevHistory, newMessage]
        return updatedHistory.length > 20 ? updatedHistory.slice(-20) : updatedHistory
      });
    })
    return () => {
      socket.off('chatMessage')
    };
  }, [])

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [chatHistory]);

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = {name, text: message}
      socket.emit('sendMessage', newMessage)
      setMessage('')
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage()
    }
  };

  return (
    <div className="chat-container w-100">
      <ListGroup className="chat-history mb-1">
        {chatHistory.map((msg, index) => (
          <ListGroup.Item
            key={index}
            className={`d-flex py-0 ps-0 mb-1 ${msg.name === name ? 'justify-content-end' : 'justify-content-start'}`}
          >
            <div className={`chat-bubble ${msg.name === name ? 'my-message' : 'other-message'}`}>
              <strong className="name">{msg.name !== name && `${msg.name}: `}</strong>
              {msg.text}
            </div>
          </ListGroup.Item>
        ))}
        <div ref={chatEndRef}/>
      </ListGroup>
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          maxLength={100}
          onKeyPress={handleKeyPress}
        />
        <Button variant="primary" size={"sm"} onClick={handleSendMessage}>
          Send
        </Button>
      </InputGroup>
    </div>
  );
};

export default Chat;
