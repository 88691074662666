import React from 'react'

const CastBar = ({castTime, spellName}) => {
  return (
    spellName && (
      <div className="cast-bar-container" style={{animationDuration: `${castTime}ms`}}>
        <div className="text-container">
          <div className="spell-name">{spellName}</div>
        </div>
        <div className="cast-bar">
        <div
            className="cast-bar-progress"
            style={{animationDuration: `${castTime}ms`}}
          />
        </div>
      </div>
    )
  );
};

export default CastBar
