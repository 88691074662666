import {ListGroup} from 'react-bootstrap'
import {getUnits, getPrefix, statsToIgnore, statToText} from '../../utils/stats'

const Stats = ({stats}) => {
  return (
    <ListGroup>
      {Object.keys(stats.secondary)
        .filter((stat) => !statsToIgnore.includes(stat))
        .sort((a, b) => sortWeights[a] > sortWeights[b])
        .map((key) => (
          <ListGroup.Item key={key} className="stats-list-item p-0 d-flex align-items-center justify-content-between">
            <small>{key}</small>
            <small>{getPrefix(key)}{statToText(stats.secondary[key])}{getUnits(key)}{key === 'armor' ? ` (${getArmorReduce(stats.secondary[key])}%)` : ''}</small>
          </ListGroup.Item>
        ))}
    </ListGroup>
  )
}

const sortWeights = {
  'max hp': 0,
  'max mana': 1,
  damage: 2,
  critical: 3,
  'spell damage': 4,
  'spell critical': 5,
  armor: 6,
  dodge: 7,
  parry: 8,
  'block chance': 9,
  'block value': 10,
  'magic resistance': 11,
  accuracy: 12,
  'attack speed': 13,
  'damage bonus': 14,
  'casting speed': 15,
  'spell damage bonus': 16,
  'hp per kill': 17,
  'mana per kill': 18,
  'skill bonus': 19,
  'magic find': 20,
  'pet damage bonus': 21,
  'earth resistance': 22,
  'fire resistance': 23,
  'water resistance': 24,
  'air resistance': 25,
  'sword damage': 26,
  'axe damage': 27,
  'dagger damage': 28,
  'polearm damage': 29,
  'mace damage': 30,
  'earth spell damage': 31,
  'fire spell damage': 32,
  'water spell damage': 33,
  'air spell damage': 34,
  'damage reduction': 35,
  'spell penetration': 36,
  'armor penetration': 37,
}

const getArmorReduce = (armorValue) => {
  let reduce = 20000 / (4000 + (armorValue / 100)) - 4;
  if (reduce < 0.1) {
    reduce = 0.1;
  }
  return Math.round((1 - reduce) * 100);
}

export default Stats
